<template>
  <div class="d-flex">
    <div class="d-flex">
      <b-badge
        @click="toggleStatus(row.id, row.is_active)"
        size="sm"
        :id="'disable' + row.id"
        v-if="disableButton && approvalAccess"
        class="mr-50 cursor-pointer"
        :variant="row.is_active == 1 ? 'danger' : 'success'"
        >{{ row.is_active == 0 ? "Enable" : "Disable" }}
      </b-badge>
      <b-badge
        v-if="approvalAccess && approve && row.approved1 !== 'Approved'"
        variant="primary"
        :id="'sales-approve' + row.id"
        class="mr-50 cursor-pointer"
        @click="approveRow(row, 'Approved')"
      >
        <feather-icon icon="CheckIcon" />
      </b-badge>
      <b-tooltip
        v-if="approvalAccess && approve && row.approved1 !== 'Approved'"
        :target="'sales-approve' + row.id"
        triggers="hover"
      >
        Approve
      </b-tooltip>

      <b-badge
        v-if="approvalAccess && reject && row.approved1 != 'Rejected'"
        variant="danger"
        :id="'sales-reject' + row.id"
        class="mr-50 cursor-pointer"
        @click="approveRow(row, 'Rejected')"
      >
        <feather-icon icon="XIcon" />
      </b-badge>

      <b-tooltip
        v-if="approvalAccess && reject && row.approved1 != 'Rejected'"
        :target="'sales-reject' + row.id"
        triggers="hover"
      >
        Reject
      </b-tooltip>
    </div>
  </div>
</template>
<script>
import { BBadge, BTooltip, BModal, BFormTextarea, BButton } from "bootstrap-vue";
import axios from "@/components/axios";
import moment from "moment";
export default {
  props: [
    "row",
    "getGridData",
    "endpoint",
    "approve",
    "reject",
    "disableButton",
    "title",
  ],
  components: { BBadge, BTooltip, BModal, BFormTextarea, BButton },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"), // JSON.parse()
    };
  },
  mounted() {
    this.getAccess();
  },
  watch: {
    "$store.state.app.user_approval": {
      handler: "getAccess",
      immediate: true,
    },
  },
  methods: {
    getAccess() {
      this.approvalAccess = this.$store.getters["app/approvalAccess"](this.title);
    },
    async approveRow(row, status) {
      this.$swal({
        title: `Are you sure to ${status == "Approved" ? "Approve" : "Reject"}?`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `${status == "Approved" ? "Approve" : "Reject"}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then(async (result) => {
          const data = {
            approved1: status,
          };
          if (status == "Approved") {
            data.approved1datetime = moment(new Date()).format();
          }
          if (result.value) {
            await axios({
              method: "put",
              url: `${this.baseApi}/${this.endpoint}/${row.id}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
              data: JSON.stringify(data),
            })
              .then((json) => {
                if (json.data.status == 200 || json.data) {
                  this.$swal({
                    icon: "success",
                    title: `${status == "Approved" ? "Approved" : "Rejected"}`,
                    text: `Successfully ${
                      status == "Approved" ? "Approved" : "Rejected"
                    }.`,
                    customClass: {
                      confirmButton: "btn btn-success",
                    },
                  });
                  this.getGridData();
                }
              })
              .catch((error) =>
                this.$swal({
                  title: "Error!",
                  text: `${error}`,
                  icon: "error",
                  timer: 5000,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                })
              );
          }
        })
        .catch((error) => console.log(error, "error"));
    },

    async toggleStatus(id, isActive) {
      const data = {
        is_active: isActive == 1 ? 0 : 1,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/isactive/${id}`,
        data: JSON.stringify(data),
      };
      await axios(requestOptions)
        .then((json) => {
          this.$swal({
            title: "Submited",
            text: json.data.message
              ? `${json.data.message}`
              : json.data.success
              ? `${json.data.success}`
              : "Update SuccessFully",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getGridData();
        })
        .catch((error) => console.log(error, "error"));
    },
  },
};
</script>
